// extracted by mini-css-extract-plugin
export var column = "ProviderBrochuresDashboardOptions__column__n4Qar";
export var flex = "ProviderBrochuresDashboardOptions__flex__pFgIM";
export var flexColumn = "ProviderBrochuresDashboardOptions__flexColumn__fvUV_";
export var gap1 = "ProviderBrochuresDashboardOptions__gap1__hrE_o";
export var gap2 = "ProviderBrochuresDashboardOptions__gap2__WOgEb";
export var gap3 = "ProviderBrochuresDashboardOptions__gap3__tOQEk";
export var gap4 = "ProviderBrochuresDashboardOptions__gap4__BZWvS";
export var gap5 = "ProviderBrochuresDashboardOptions__gap5__q1sH9";
export var popoverBody = "ProviderBrochuresDashboardOptions__popoverBody__OhFZZ";
export var popoverBodyLink = "ProviderBrochuresDashboardOptions__popoverBodyLink__c0GqP";
export var popoverBodyLinkIcon = "ProviderBrochuresDashboardOptions__popoverBodyLinkIcon__Fdv8s";
export var row = "ProviderBrochuresDashboardOptions__row__y0sgT";