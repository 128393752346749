// extracted by mini-css-extract-plugin
export var barContainer = "ProvidersDashboardNavbar__barContainer___eQP2";
export var barWrapper = "ProvidersDashboardNavbar__barWrapper__MuGpk";
export var column = "ProvidersDashboardNavbar__column__KJtRY";
export var flex = "ProvidersDashboardNavbar__flex__FHfdL";
export var flexColumn = "ProvidersDashboardNavbar__flexColumn__z6t4H";
export var gap1 = "ProvidersDashboardNavbar__gap1__vYSDz";
export var gap2 = "ProvidersDashboardNavbar__gap2__qO6E5";
export var gap3 = "ProvidersDashboardNavbar__gap3__nrMcX";
export var gap4 = "ProvidersDashboardNavbar__gap4__izS_U";
export var gap5 = "ProvidersDashboardNavbar__gap5__cnZ4Z";
export var linkContainer = "ProvidersDashboardNavbar__linkContainer__grY9x";
export var linkItem = "ProvidersDashboardNavbar__linkItem__lE82M";
export var row = "ProvidersDashboardNavbar__row__RY7Uy";
export var underlinedLink = "ProvidersDashboardNavbar__underlinedLink__GQaKS";