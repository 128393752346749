// extracted by mini-css-extract-plugin
export var column = "ProviderBrochuresDashboardProviderFilter__column__MjZUH";
export var dropdownBody = "ProviderBrochuresDashboardProviderFilter__dropdownBody__VW0L6";
export var dropdownBodyRow = "ProviderBrochuresDashboardProviderFilter__dropdownBodyRow__hYDbi";
export var dropdownTrigger = "ProviderBrochuresDashboardProviderFilter__dropdownTrigger__fgzzF";
export var filterContainer = "ProviderBrochuresDashboardProviderFilter__filterContainer__ysXOU";
export var flex = "ProviderBrochuresDashboardProviderFilter__flex__YeBEi";
export var flexColumn = "ProviderBrochuresDashboardProviderFilter__flexColumn__hXfRf";
export var gap1 = "ProviderBrochuresDashboardProviderFilter__gap1__nP3pt";
export var gap2 = "ProviderBrochuresDashboardProviderFilter__gap2__WfJEl";
export var gap3 = "ProviderBrochuresDashboardProviderFilter__gap3__ugSF6";
export var gap4 = "ProviderBrochuresDashboardProviderFilter__gap4__gQ2Du";
export var gap5 = "ProviderBrochuresDashboardProviderFilter__gap5__ytHzj";
export var label = "ProviderBrochuresDashboardProviderFilter__label__pXqve";
export var providersDropdown = "ProviderBrochuresDashboardProviderFilter__providersDropdown__Fbtae";
export var providersSearch = "ProviderBrochuresDashboardProviderFilter__providersSearch__fhD7I";
export var row = "ProviderBrochuresDashboardProviderFilter__row__F7pGA";
export var searchField = "ProviderBrochuresDashboardProviderFilter__searchField__G0vKg";